<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="cardName"
                    label="卡名称"
                >
                    <el-input
                        v-model="queryFormModel.cardName"
                        placeholder="请输入卡名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="cardName"
                    label="卡名称"
                    min-width="100"
                />
                <el-table-column
                    prop="cardAmount"
                    label="卡金额"
                    min-width="100"
                />
                <el-table-column
                    prop="cardLogoUrl"
                    label="图标"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.cardLogoUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="cardName"
                    label="卡名称"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.cardName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardAmount"
                    label="卡金额"
                    label-width="7em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.cardAmount"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardLogo"
                    label="图标"
                    label-width="7em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.cardLogo"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardDesc"
                    label="卡描述"
                    label-width="7em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.cardDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="validityValue"
                    label="有效期"
                    label-width="7em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        :precision="0"
                        v-model.number="addDialog.formModel.validityValue"
                    />
                    <dictionaries-picker
                        type="validityType"
                        v-model="addDialog.formModel.validityType"
                        style="width: 100px"
                    />
                </el-form-item>
                <el-form-item
                    label="权益卡详情"
                    label-width="7em"
                >
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddItem"
                            >
                                新增
                            </el-button>
                        </el-button-group>
                    </div>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="benefitCardDetailList"
                    >
                        <el-table-column
                            prop="benefitCardDetailType"
                            label="权益卡类型"
                            width="150"
                        >
                            <tempalte slot-scope="scope">
                                <dictionaries-picker
                                    type="benefitCardDetailType"
                                    v-model="scope.row.benefitCardDetailType"
                                />
                            </tempalte>
                        </el-table-column>
                        <el-table-column
                            prop="benefitCardDetailName"
                            label="权益卡名称"
                            width="200"
                        >
                            <tempalte slot-scope="scope">
                                <el-input
                                    v-model="scope.row.benefitCardDetailName"
                                    auto-complete="off"
                                />
                            </tempalte>
                        </el-table-column>
                        <el-table-column
                            label="权益卡配置"
                            min-width="100"
                        >
                            <tempalte
                                slot-scope="scope"
                                v-if="+scope.row.benefitCardDetailType === 1">
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAddSelectCoupon(scope.row, 1)"
                                >
                                    选择优惠券
                                </el-button>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="scope.row.couponList"
                                    style="width: 100%"
                                    class="ma-t"
                                >
                                    <el-table-column
                                        prop="templetName"
                                        label="模板名称"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="templetTypeName"
                                        label="模板类型"
                                        width="100"
                                    />
                                    <el-table-column
                                        prop="couponAmount"
                                        label="优惠券金额"
                                        width="100"
                                    />
                                    <el-table-column
                                        prop="couponUseAmount"
                                        label="优惠券满减限制"
                                        width="110"
                                    />
                                    <el-table-column
                                        prop="remarks"
                                        label="模板备注"
                                        min-width="100"
                                    />
                                </el-table>
                                <div class="ma-t">领取数量：<el-input-number
                                    controls-position="right"
                                    :min="1"
                                    :precision="0"
                                    v-model.number="scope.row.receiveNumber"
                                /></div>
                            </tempalte>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="60"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onDeleteItem(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="7em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>


        <el-dialog
            title="选择优惠券"
            center
            width="1200px"
            :visible.sync="selectCouponDialog.isVisible"
            @closed="onSelectCouponCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectCouponDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectCouponDialog.queryFormModel"
            >
                <el-form-item
                    prop="templetName"
                    label="模板名称"
                >
                    <el-input
                        v-model="selectCouponDialog.queryFormModel.templetName"
                        placeholder="请输入模板名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectCouponDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectCouponDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectCouponDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="couponTempletId"
                    ref="selectCouponDialogTable"
                    @selection-change="onSelectCouponDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="templetName"
                        label="模板名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="templetTypeName"
                        label="模板类型"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponAmount"
                        label="优惠券金额"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponUseAmount"
                        label="优惠券满减限制"
                        min-width="100"
                    />
                    <el-table-column
                        prop="remarks"
                        label="模板备注"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100"
                        v-if="selectCouponDialog.type === 1"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectCouponConfirm(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectCouponDialog.pagination"
                        @input="onSelectCouponDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectCouponConfirm"
                    v-if="selectCouponDialog.type === 2"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCouponCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PrepaidCard',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                cardName: '', // 卡名称
                isAvailable: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cardName: '',
                    cardAmount: '',
                    cardLogo: [],
                    cardDesc: '',
                    isAvailable: true,
                    validityType: 5,
                    validityValue: 1,
                },
                // 表单校验规则
                formRules: {
                    cardName: {
                        required: true,
                        message: '请输入卡名称',
                        trigger: 'blur',
                    },
                    cardAmount: {
                        required: true,
                        message: '请输入卡金额',
                        trigger: 'blur',
                    },
                    cardLogo: {
                        type: 'array',
                        required: true,
                        message: '请上传充值卡图片',
                    },
                    isAvailable: {
                        required: true,
                        message: '请选择是否可用',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            benefitCardDetailList: [],
            selectCouponDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    templetName: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
                type: 1,
                row: {},
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.BenefitCard.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
                this.benefitCardDetailList = [];
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Mb.BenefitCard.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res.benefitCard);
                this.benefitCardDetailList = res.benefitCardDetailList;
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.BenefitCard.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.BenefitCard.save({
                    ...this.addDialog.formModel,
                    detailJson: JSON.stringify(this.benefitCardDetailList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.BenefitCard.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },

        onAddItem() {
            this.benefitCardDetailList.push({
                couponList: [],
                receiveNumber: 1,
                benefitCardDetailName: '',
                benefitCardDetailType: '',
                isAvailable: true,
            });
        },
        onDeleteItem(row) {
            this.benefitCardDetailList.splice(this.benefitCardDetailList.indexOf(row), 1);
        },

        onAddSelectCoupon(row, type) {
            this.selectCouponDialog.type = type;
            this.selectCouponDialog.row = row;
            this.selectCouponDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectCouponDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectCouponDialogTable) {
                    this.$refs.selectCouponDialogTable.clearSelection();
                }
                this.selectCouponDialog.row.couponList.forEach(item => {
                    this.$refs.selectCouponDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectCouponDialogQuery(pagination) {
            return this.$api.Pm.CouponTemplet.data({
                ...this.selectCouponDialog.queryFormModel,
                ...pagination,
                isAvailable: 1,
            }).then(json => {
                const res = json.data;
                this.selectCouponDialog.tableData = res.data;
                this.selectCouponDialog.pagination = res.pagination;
            });
        },
        onSelectCouponDialogSelectionChange(val) {
            this.selectCouponDialog.currentTableSelect = val;
        },
        onResetSelectCouponDialog() {
            this.$refs.selectCouponDialogQueryForm.resetFields();
        },
        onSelectCouponConfirm(row) {
            // 插入新数据
            const list = [];
            if (this.selectCouponDialog.type === 1) {
                const item = { ...row, couponNum: 1 };
                this.selectCouponDialog.row.couponList = [item];
                this.selectCouponDialog.row.couponTempletId = item.id;
            } else if (this.selectCouponDialog.type === 2) {
                this.selectCouponDialog.currentTableSelect.forEach(item => {
                    let isHave = false;
                    this.selectCouponDialog.row.couponList.forEach(oldItem => {
                        if (item.couponTempletId === oldItem.couponTempletId) {
                            list.push({ ...oldItem });
                            isHave = true;
                        }
                    });
                    if (!isHave) {
                        // 添加新数据
                        list.push({ ...item });
                    }
                });
                this.selectCouponDialog.row.couponList = [...list];
            }
            this.selectCouponDialog.isVisible = false;
        },
        onSelectCouponCancel() {
            this.selectCouponDialog.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
</style>
