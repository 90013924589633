<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
            >
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>基本信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="卡号"
                                label-width="8em"
                            >
                                {{ buyerBenefitCard.cardSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="卡名称"
                                label-width="8em"
                            >
                                {{ buyerBenefitCard.cardName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="有效期开始"
                                label-width="8em"
                            >
                                {{ buyerBenefitCard.validBeginTime | moment('YYYY-MM-DD HH:mm:ss')}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="有效期结束"
                                label-width="8em"
                            >
                                {{ buyerBenefitCard.validEndTime | moment('YYYY-MM-DD HH:mm:ss')}}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey680', '付款信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey682', '付款时间')"
                                label-width="8em"
                            >
                                {{ buyerBenefitOrder.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey568', '付款类型')"
                                label-width="8em"
                            >
                                {{ buyerBenefitOrder.paymentTypeName }}({{ buyerBenefitOrder.payableAmount }} 元)<span v-if="buyerBenefitOrder.paymentComments">/{{ buyerBenefitOrder.paymentComments }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey683', '商户付款单号')"
                                label-width="8em"
                            >
                                {{ buyerBenefitOrder.paymentSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey684', '支付付款单号')"
                                label-width="8em"
                            >
                                {{ buyerBenefitOrder.externalPaymentSn }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey979', '买家信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey63', '用户名')"
                                label-width="8em"
                            >
                                {{ buyerBenefitCard.userName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey980', '手机号')"
                                label-width="8em"
                            >
                                {{ buyerBenefitCard.countryCode }}-{{ buyerBenefitCard.mobile }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>权益卡信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="卡名称"
                                label-width="8em"
                            >
                                {{ benefitCard.cardName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="卡金额"
                                label-width="8em"
                            >
                                {{ benefitCard.cardAmount }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="卡描述"
                                label-width="8em"
                            >
                                {{ benefitCard.cardDesc }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="有效期"
                                label-width="8em"
                            >
                                {{ benefitCard.validityValue }}{{ benefitCard.validityTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="详情"
                                label-width="8em"
                            >
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="benefitCard.detailList"
                                >
                                    <el-table-column
                                        prop="benefitCardDetailTypeName"
                                        label="权益卡类型"
                                        width="100"
                                    />
                                    <el-table-column
                                        prop="benefitCardDetailName"
                                        label="权益卡名称"
                                        width="200"
                                    />
                                    <el-table-column
                                        label="权益卡配置"
                                        min-width="100"
                                    >
                                        <tempalte
                                            slot-scope="scope"
                                            v-if="+scope.row.benefitCardDetailType === 1">
                                            <el-table
                                                stripe
                                                border
                                                size="small"
                                                :data="scope.row.couponList"
                                                style="width: 100%"
                                                class="ma-t"
                                            >
                                                <el-table-column
                                                    prop="templetName"
                                                    label="模板名称"
                                                    min-width="100"
                                                />
                                                <el-table-column
                                                    prop="templetTypeName"
                                                    label="模板类型"
                                                    width="100"
                                                />
                                                <el-table-column
                                                    prop="couponAmount"
                                                    label="优惠券金额"
                                                    width="100"
                                                />
                                                <el-table-column
                                                    prop="couponUseAmount"
                                                    label="优惠券满减限制"
                                                    width="110"
                                                />
                                                <el-table-column
                                                    prop="remarks"
                                                    label="模板备注"
                                                    min-width="100"
                                                />
                                            </el-table>
                                            <div class="ma-t">领取数量：{{scope.row.receiveNumber}}</div>
                                        </tempalte>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
            </el-form>
            <div class="pa-t">
                <el-button
                    size="small"
                    type="primary"
                    @click="$router.back()"
                >
                    {{ $t('pageKey126', '返 回') }}
                </el-button>
            </div>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'OrderDetail',
    mixins: [pagesMixin],
    data() {
        return {
            id: '',
            buyerBenefitCard: {},
            benefitCard: {},
            buyerBenefitOrder: {},
        };
    },
    methods: {
        init() {
            this.$api.Mb.BuyerBenefitCard.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.buyerBenefitCard = res.buyerBenefitCard || {};
                this.benefitCard = res.benefitCard || {};
                this.buyerBenefitOrder = res.buyerBenefitOrder || {};
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    border-radius: 100em;
    background-color: $color-danger;
    color: #fff;
    line-height: 24px;
    min-width: 24px;
    text-align: center;
}
</style>
